import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: "bold",
  },
  accordion: {
    marginBottom: "1rem",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    background: theme.palette.primary.light,
    color: theme.palette.primary.main,
    "&:hover": {
      boxShadow: "1px 1px 3px #888888",
    },
  },
  icon: {
    color: theme.palette.primary.main,
  },
}));

export default function SimpleAccordion() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {liste.map((data) => (
        <Accordion className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={classes.icon} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>{data.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2">{data.text}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}

const liste = [
  {
    title: "Welche Unterlagen brauche ich zur Registrierung?",
    text: "Zur Registrierung brauchen wir von Ihnen nur Ihre Kontaktdaten inkl. Anschrift und Emailadresse. Im Anschluss können Sie im Login-Bereich Ihre Zulassungsbescheinigung (Teil 1) für Ihr E-Fahrzeug hochladen. Für die Überweisung Ihrer Auszahlung benötigen wir zudem Ihre Kontodaten. Diese können ebenfalls im Login-Bereich angegeben werden.",
  },
  {
    title: "Kann auch ein Leasing-Fahrzeug registriert werden?",
    text: "Das geht, wenn der Name, den Sie zur Registrierung nutzen und der Name in der Zulassungsbescheinigung übereinstimmen.",
  },
  {
    title: "Was muss ich tun, um von THG-Quotenhandel zu profitieren?",
    text: "Als Halter*in eines E-Fahrzeugs wird Ihnen wird jährlich ein pauschaler Betrag für Ihren Ladestrom gutgeschrieben. Dieser liegt momentan bei 2.000 kWh für E-Fahrzeuge der Klasse M1, 3.000 kWh für E-Fahrzeuge der Klasse N1 und 72.000 kWh für E-Fahrzeuge der Klasse M3. Sie müssen sich lediglich auf unserer Website registrieren und ein Foto oder Scan Ihrer Zulassungsbescheinigung (Teil 1) hochladen.",
  },
  {
    title: "Wie häufig werden die Zusatzerlöse ausgezahlt?",
    text: "Sie bekommen für jedes Kalenderjahr  aufs Neue eine “Gutschrift”. Daher werden auch die Erlöse aus dem Handel mit Ihrem THG-Quoten jährlich an Sie ausgezahlt.",
  },
  {
    title: "Wann kann ich mit einer Auszahlung rechnen?",
    text: "Sobald Ihre Quote vom Umweltbundesamt verifiziert wurde. Dies dauert in der Regel nur wenige Wochen nach Upload Ihrer Zulassungsbescheinigung (Teil 1). In unserer Statusabfrage im Login-Bereich können Sie den aktuellen Stand Ihres Antrags jederzeit nachverfolgen.",
  },
  {
    title: "Kann ich auch ein Hybridfahrzeug registrieren?",
    text: "Nein, aktuell bekommen nur reine Elektrofahrzeuge (BEV) THG-Quoten gutgeschrieben. Ausgeschlossen sind somit auch Plug-in Hybridfahrzeuge.",
  },
  {
    title: "Ist der THG-Quotenservice der M3E kostenpflichtig?",
    text: "Nein. Für Sie entstehen durch unsere Arbeit keinerlei Mehrkosten, ausschließlich zusätzliche Erlöse. M3E finanziert sich über den Weiterverkauf der gebündelten Quoten. ",
  },
  {
    title:
      "Mein E-Fahrzeug ist NICHT in Deutschland zugelassen. Kann ich es trotzdem registrieren?",
    text: "Das ist leider nicht möglich. Die Verifizierung und Nachweiserstellung der THG-Quoten beim Umweltbundesamt gilt nur für in Deutschland zugelassene Fahrzeuge. ",
  },
  {
    title: "Was ist die “Pflanz einen Baum”-Aktion?",
    text: "Es besteht zudem die Möglichkeit, im Rahmen der “Pflanz einen Baum”-Aktion noch mehr Gutes fürs Klima zu tun! Wenn Sie Ihr Fahrzeug registrieren, erhalten Sie 255 Euro an Erlösen aus dem Verkauf Ihrer THG-Quoten und steuern zusätzlich noch etwas zur Aufforstung der Wälder bei.",
  },
];
